const aiBaseUrl = process.env.VUE_APP_AI_API_BASE_URL

export default function (api) {
  return {
    // isAiApi
    // get AI /merchant/1
    getAIMerchant (id) {
      return api.get(`${aiBaseUrl}/merchant/${id}`, {
        shouldAiJWT: true,
        isAiApi: true,
      })
    },
    // post /demo/c3045c02e88943de8aaeb7d28ac621bc
    postAiDemo (data) {
      return api.post(`${aiBaseUrl}/demo/c3045c02e88943de8aaeb7d28ac621bc`, data, {
        shouldAiJWT: true,
        isAiApi: true,
        isReflush: true,
      })
    },
    // get /speech/token
    getSpeechToken () {
      return api.get(`${aiBaseUrl}/speech/token`, {
        shouldAiJWT: true,
        isAiApi: true,
      })
    },
    getChatHistory (id, data) {
      return api.get(`${aiBaseUrl}/merchant/${id}/chatHistory`, {
        shouldAiJWT: true,
        isAiApi: true,
        params: data,
      })
    },
    getQuery (data, setResponseText) {
      return api.get(`${aiBaseUrl}/query`, {
        shouldAiJWT: true,
        noLoading: true,
        isAiApi: true,
        params: data,
        onDownloadProgress: (progressEvent) => {
          const dataChunk = progressEvent.event.target.response
          setResponseText(dataChunk)
        }
      })
    },
    putQuery (id, data) {
      return api.put(`${aiBaseUrl}/query/${id}`, data, {
        isAiApi: true,
        shouldAiJWT: true,
      })
    },
    getCommonQuestion (data) {
      return api.get(`${aiBaseUrl}/query/commonQuestion`, {
        shouldAiJWT: true,
        isAiApi: true,
        params: data,
      })
    },
  }
}
