import { createAPI } from './create-api'
import auth from './module/auth'
import store from '@/store'
import router from "@/router";
import { showLoading, hideLoading, getRefreshToken } from "../utils/utils";
import { showFailToast } from 'vant'
import i18n from "@/lang";



const userApiBaseUrl = process.env.VUE_APP_API_BASE_URL;
let refreshTokenPromise;
const api = createAPI({ baseURL: userApiBaseUrl + '/web' })
api.interceptors.request.use(
  (config) => {
    const data = config.data || {};
    let shouldJWT = config.shouldJWT || data.shouldJWT;
    if (shouldJWT && store.getters.token) {
      config.headers["X-Auth-TokenBody"] = `${store.getters.token}`;
    }
    let shouldAiJWT = config.shouldAiJWT || data.shouldAiJWT;
    if (shouldAiJWT && store.getters.token) {
      config.headers["Authorization"] = `Bearer ${store.getters.token}`;
    }

    // 获取本地缓存的语言
    const locale = i18n.global.locale.value
    config.headers['Accept-Language'] = locale
    if (data.shouldJWT !== undefined) {
      delete config.data.shouldJWT;
    }
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

//响应拦截器
let goLoginFlagTimer = null;
api.interceptors.response.use(
  (response) => {
    console.log(router.currentRoute.value.name)
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    let routeId = router.currentRoute.value.params.id || null;
    // 如果不存在id，就从缓存中取
    if (!routeId) {
      routeId = localStorage.getItem('merchantId') || null
    }
    const isAiApi = config.isAiApi
    const isAzureApi = config.isAzureApi
    if (!noLoading) {
      hideLoading();
    }
    if (isAiApi) {
      // ai相关接口
      if (status === 401 && !error.config._retry && !error.config.isReflush) {
        // 如果服务器返回认证错误&&不是第二次重试&&不是刷新接口
        if (!refreshTokenPromise) {
          // 如果没有创建刷新token的promise的情况下创建一个刷新token的Promise
          error.config._retry = true;
          refreshTokenPromise = getRefreshToken().then(() => {
          }).finally(() => {
            refreshTokenPromise = null;
          });
        }
        // 返回一个刷新token的promise
        return refreshTokenPromise
          .then(() => {
            // 当刷新token的promise返回成功时重新尝试调用接口
            return api.request(error.config);
          })
          .catch(() => {
            // 刷新失败则退出并返回错误
            return Promise.reject(error);
          });
      }
      switch (status) {
        case 400:
          const errorList = error?.response?.data;
          if (typeof errorList === "object") {
            errorList.forEach((errorMessage) => {
              showFailToast(errorMessage)
            });
          }
          break;

        default:
          break;
      }
    }

    return Promise.reject(error);
  }
);

export const authApi = auth(api)