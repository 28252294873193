let loadingCount = 0
let loadingTimer = null
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import { authApi } from "@/api";

const startLoading = () => {
    store.dispatch('setLoadingShow', true)
}

const endLoading = () => {
    store.dispatch('setLoadingShow', false)
}

export const showLoading = () => {
    clearTimeout(loadingTimer)
    loadingTimer = setTimeout(() => {
        loadingCount = 1
        hideLoading()
    }, 30000)
    if (loadingCount === 0) {
        startLoading()
    }
    loadingCount += 1
}

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return
    }
    loadingCount -= 1
    if (loadingCount === 0) {
        clearTimeout(loadingTimer)
        endLoading()
    }
}
// https://www.techonthenet.com/js/language_tags.php
// https://toutiao.io/posts/9uum6d/preview
export const langList = [
    {
        label: '繁體中文',
        code: 'zh-TW',
        subCode: ['zh-HK', 'zh-MO', 'zh-TW'],
    },
    {
        label: '简体中文',
        code: 'zh-CN',
        subCode: ['zh', 'zh-CN', 'zh-SG'],
    },
    {
        label: '日本語',
        code: 'ja-JP',
        subCode: ['ja', 'ja-JP'],
    },
    {
        label: 'English',
        code: 'en-US',
        subCode: ['en', 'en-AU', 'en-BZ', 'en-CA', 'en-CB', 'en-IE', 'en-JM', 'en-NZ', 'en-PH', 'en-ZA', 'en-TT', 'en-GB', 'en-US', 'en-ZW'],
    }
]

// https://www.techonthenet.com/js/language_tags.php
export const getLang = () => {
    // 获取当前的lang
    let lang = localStorage.getItem('lang')
    if (!lang) {
        // 获取浏览器的lang
        lang = navigator.language || navigator.userLanguage
    }
    // 从langList的subCode中查找
    const langItem = langList.find((item) => {
        if (item.subCode.includes(lang)) {
            return item.code
        }
    })

    if (langItem) {
        lang = langItem.code
    } else {
        lang = 'en-US'
    }
    return lang
}

// setLang
export const setLang = (lang) => {
    localStorage.setItem('lang', lang)
}

// html的img标签，src属性的值是相对路径，需要拼接上服务器地址
export const formatImgSrc = (html) => {
    const pathAssets = process.env.VUE_APP_PATH_ASSETS
    const domain = store.getters.merchant?.base?.domain || '/'
    const reg = /<img.*?(?:>|\/>)/gi
    const regSrc = /src=[\'\"]?([^\'\"]*)[\'\"]?/i
    const imgs = html.match(reg)
    if (imgs) {
        for (let i = 0; i < imgs.length; i++) {
            const src = imgs[i].match(regSrc)
            if (src && src[1]) {
                html = html.replace(src[1], domain + pathAssets + src[1])
            }
        }
    }
    return html
}

export const getDistanceString = (distance = 0, isKm = true) => {
    console.log(distance)
    if (isKm) {
        distance = parseInt(distance * 1000)
    }
    if (distance < 1000) {
        distance = distance + 'm'
    } else {
        distance = Math.round(distance / 100) / 10 + 'km'
    }
    return distance
}

export const downloadImage = async (imageSrc) => {
    try {
        // fetchで画像データを取得
        const image = await fetch(imageSrc);
        const imageBlob = await image.blob();
        const imageURL = URL.createObjectURL(imageBlob);

        // 拡張子取得
        const mimeTypeArray = imageBlob.type.split('/');
        const extension = mimeTypeArray[1];

        // ダウンロード
        const link = document.createElement('a');
        link.href = imageURL;
        link.download = `fileName.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        throw new Error(`${error}. Image src: ${imageSrc}`);
    }
}

export const goToGoogleMap = (lat, lng) => {
    // https://www.google.com/maps/search/?api=1&query=36.26577,-92.54324
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    window.open(url, '_blank')
}

// 获取本地坐标
export const getLocation = () => {
    const promise = new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            showLoading()
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    resolve(pos)
                    hideLoading()
                },
                (err) => {
                    console.log(err)
                    reject(err)
                    hideLoading()
                }
            )
        } else {
            console.warn('Geolocation is not supported by this browser.')
            reject('Geolocation is not supported by this browser.')
        }
    })
    return promise
}

export const setLastRoute = (route) => {
    const routeData = {
        name: route.name,
        params: route.params,
        query: route.query,
    }
    sessionStorage.setItem('lastRoute', JSON.stringify(routeData))
}
export const getLastRoute = () => {
    try {
        const route = JSON.parse(sessionStorage.getItem('lastRoute'))
        // 清空
        sessionStorage.removeItem('lastRoute')
        return route
    } catch (error) {
        console.warn(error)
    }
    // 清空
    sessionStorage.removeItem('lastRoute')
    return null
}
// 解析token jwt的exp 是否过期
export const tokenIsExp = (jwt) => {
    if (!jwt) {
        return true
    }
    const token = jwt.split('.')[1]
    const tokenObj = JSON.parse(atob(token))
    const exp = tokenObj.exp
    const now = Math.floor(Date.now() / 1000)
    console.log(exp, now)
    return exp < (now + 60) // check if token is going to expire in the next minute
}

// 生成uuid
export const uuid = () => {
    let d = Date.now()
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now()
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
}

export const setUserUUID = () => {
    // 从storage中取
    let userUUID = localStorage.getItem('userUUID')
    if (!userUUID) {
        userUUID = uuid()
        localStorage.setItem('userUUID', userUUID)
    }
    return userUUID
}
export const getRefreshToken = async () => {
    const promise = authApi
        .postAiDemo({
            username: setUserUUID(),
        })
        .then((res) => {
            const token = res.data.token
            store.dispatch('setToken', '')
            localStorage.removeItem('token')
            localStorage.setItem('token', token)
            store.dispatch('setToken', token)
        })
        .catch((err) => {
            return false
        })
    return promise
}