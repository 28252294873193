import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { setLastRoute, getLastRoute } from '@/utils/utils'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */ '../views/merchant/Index.vue'),
    children: [


      // AiChat
      {
        path: '',
        name: 'AiChat',
        component: () => import(/* webpackChunkName: "AiChat" */ '../views/merchant/aiChat/AiChat.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: true,
        }
      },
      // Error
      {
        path: 'error/:code',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/merchant/error/Error.vue'),
        meta: {
          showTopBar: true,
          shouldLogin: false,
        }
      },

    ]
  },

  // 404
  {
    path: '/not-found',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/notFound/NotFound.vue'),
    meta: {
      showTopBar: false,
    }
  },
  {
    path: '/error/:code',
    name: 'ErrorNoMerchantId',
    component: () => import(/* webpackChunkName: "Error" */ '../views/merchant/error/Error.vue'),
    meta: {
      showTopBar: false,
    }
  },
  // 不存在的路由跳转到404
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found',
    meta: {
      showTopBar: false,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.afterEach((to, from) => {
  console.log("afterEach");
});


export default router
